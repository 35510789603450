
import React from 'react'
import { useEffect, useState } from "react";
import { ENV, globalCurrentDate, sendRequestApi } from "../../utils";
import CommentItem from './CommentItem';
import CommentForm from "./CommentForm";

export default function CommentsList(props) {
    const {
        picture
    } = props;

    const [comments, setComments] = useState([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        getAllComments();
    }, [picture])
    
    const getAllComments = async() => {
        const response = await sendRequestApi({
            method: 'GET',
            endpoint: `${ENV.ENDPOINTS.COMMENTS.GET_COMMENTS}/${picture._id}`,
        })
        
        setComments(response.comments);
        setLoading(false);
    }

    const onNewComment = (comment) => {
        setComments([
            ...comments,
            comment
        ]);
    }
    
    
    return (
        <div className="container" style={{marginBottom: "20px"}}>
            <div className="card">
                <div className='card-header'>
                    <div className='card-title'>
                        <h2 className="text-center">Comments</h2>
                    </div>
                </div>

                <div className='card-body' style={{minHeight: "20px"}}>
                    <div className='row'>
                        {(loading) && <p className="text-center">Loading... <i className='fa fa-refresh fa-spin'></i></p>}
                        {(!loading && comments.length < 1) && <p className="text-center">No comments...</p>}
                        {(!loading && comments.length > 0) && (
                            comments.map(function(comment, index){
                                return (
                                    <div className="col-sm-12 form-group mx-auto" key={index}>
                                        <CommentItem comment={comment} />
                                    </div>
                                )
                            })
                        )}
                    </div>

                    <div className="row">
                        <CommentForm picture={picture} onNewComment={onNewComment} />
                    </div>
                </div>
            </div>

            
        </div>
    )
}
