import React, { useState } from 'react'
import { useAuth } from '../../hooks';
import * as Yup from "yup";
import { Formik, Form as FormFormik, Field, ErrorMessage } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ENV, sendRequestApi } from '../../utils';
import {toast} from "react-hot-toast";

const initialValues = (picture) => {
    return {
        content: "",
        picture: picture._id,
    }
}

const validationScheme = () => {
    return Yup.object({
        content: Yup.string().required("The content is required"),
    })
}

export default function CommentForm({picture, onNewComment = () => {}}) {
    const [newComment, setNewComment] = useState(null)

    const { user } = useAuth();

    if(!user || user == null){
        return (
            <p className='text-center'>
                Please register or login to comment
            </p>
        );
    }

    const onSubmit = async(values, actions) => {
        const _params = {
            method: 'POST',
            params: values,
            endpoint: `${ENV.ENDPOINTS.COMMENTS.STORE_COMMENT}/${picture._id}`,
        }

        const response = await sendRequestApi(_params);

        if(response.status == 1){
            onNewComment(response.comment);
            toast.success(response.message);
            actions.resetForm();
        } else if(response.status == 0){
            toast.error(response.message);
        }
    }

    return (
        <>
            <div className="container-fluid" style={{marginBottom: 20}}>
                <div className="row">
                    <div className='col-sm-12' style={{paddingTop: 10}}>
                        <h4 className='text-center'>New Comment</h4>
                    </div>
                </div>

                <div className="col-sm-12 form-group" style={{padding: 20}}>

                <Formik
                    initialValues={initialValues(picture)}
                    validationSchema={validationScheme()}
                    enableReinitialize
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                >

                    {({ setFieldValue, isSubmitting, handleSubmit }) => (
                        <FormFormik className="form form-inline" onSubmit={handleSubmit}>
                            <section className="row">
                                <div className="col-sm-12 mb-3">
                                    <label className="form-label mb-1">
                                        <span className="text-black">Comment <i className="valueRequired text-red">*</i>
                                        </span>
                                    </label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="content"
                                        as={"textarea"}
                                    />

                                    <ErrorMessage
                                        component="p"
                                        name="content"
                                        className="text-red text-sm"
                                    />
                                </div>

                                <div className="col-sm-12 text-center">
                                    <button className="btn btn-success btn-lg" disabled={isSubmitting} type="submit">
                                        {isSubmitting ? (
                                        <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                                        ) : (
                                        "Comment"
                                        )}
                                    </button>
                                </div>
                            </section>
                        </FormFormik>
                    )}
                
                </Formik>

                </div>
            </div>

            
        </>
    )
}
