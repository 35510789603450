import { useEffect, useState } from "react";
import { ENV, sendRequestApi } from "../utils";
import { useAuth } from "../hooks";

const FavoritesView = (props) => {
    const {user} = useAuth();
    const [favorites, setFavorites] = useState([])

    useEffect(() => {
        (async() => {
            if(user){
                await getFavorites();
            } else {
                setFavorites([]);
            }
        })()
    }, [user]);

    const getFavorites = async() => {
        const response = await sendRequestApi({
            method: 'GET',
            endpoint: ENV.ENDPOINTS.IMAGES.GET_FAVORITES,
        });

        setFavorites(response.favorites);
    }

    if(!user){
        return (
            <div className="text-center">
                <h4>Por favor inicia sesión para ver tus favoritos.</h4>
            </div>
        )
    }

    if(favorites.length < 1){
        return (
            <div className="text-center">
                <h4>No tienes favoritos.</h4>
            </div>
        )
    }

    return (
        <div className="container">
            <div className="text-center">
                <h3>{favorites.length} favoritos</h3>
            </div>

            <div className="row row-cols-1 row-cols-md-2 g-4">
                {favorites.map((favorite, index) => (
                    <div key={index} className="col">
                        <div className="card h-100">
                            <img src={favorite.picture_id.url} style={{maxHeight: 300}} className="card-img-top" alt={favorite.picture_id.title} />
                            <div className="card-body">
                                <h5 className="card-title">{favorite.picture_id.title}</h5>
                                <p className="card-text text-justify">{favorite.picture_id.explanation}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FavoritesView;