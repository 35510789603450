import { useState } from "react";

import * as DatePickerReact from "react-datepicker";

const DatePicker = (props) => {

    let {
        date = "",
        onChangeDate = () => {}
    } = props;

    if(date && date.trim() !== ""){
        date = new Date(`${date} 00:00:00`);
    } else {
        date = new Date();
    }

    //const [startDate, setStartDate] = useState(date);
    const startDate = date;

    return (
        <DatePickerReact.default
            dateFormat="yyyy-MM-dd"
            showIcon
            selected={startDate}
            maxDate={new Date()}
            onChange={(date) => {
                //setStartDate(date);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                
                if(day <= 9){
                    day = '0'+day;
                }
                
                if(month <= 9){
                    month = '0'+month;
                }
                
                var t = `${year}-${month}-${day}`;
                onChangeDate(t);
            }}
        />
    )
}

export default DatePicker;