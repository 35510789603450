import * as moment from "moment";
import { ENV, linkDownloadPicture, sendRequestApi } from "../../utils";
import { useAuth } from "../../hooks";
import toast from "react-hot-toast";
import CommentsList from "../comments/CommentsList";


const Picture = (props) => {

    const { user, logout } = useAuth();

    const {
        picture = null,
        fechaAnterior = null,
        fechaSiguiente = null,
        onNextOrPrevious = (date) => {},
        onPictureChanged = () => {}
    } = props

    const formatLikesText = () => {
        let total = 0;

        if(picture && picture.total_favorites){
            total = Number(picture.total_favorites);
        }

        if(isNaN(total)){
            total = 0;
        }

        return ( total > 1 || total === 0 )
        ? (`${total} Likes`)
        : (`${total} Like`)
    }

    if(!picture){
        return null;
    }

    const validDate = () => {
        const result = fechaSiguiente && moment(`${fechaSiguiente} 00:00:00`) < moment();
        console.log(result);
        return result;
    }

    const makeFavorite = async() => {
        if(user){
            let endpoint = ENV.ENDPOINTS.IMAGES.MARK_FAVORITE;

            if(picture.favorite_by_me){
                endpoint = ENV.ENDPOINTS.IMAGES.REMOVE_FAVORITE;
            }

            endpoint += ('/' + picture._id);

            const response = await sendRequestApi({
                method: 'POST',
                endpoint: endpoint,
                params: {
                    end: 1,
                }
            })

            if(response.status == 0){
                toast.error(response.message);
            } else if(response.status == 1) {
                onPictureChanged(response.picture);
            }

        } else {
            alert("Please, register and login...");
        }
    }

return (
    <>
        <div className="card text-dark  mb-3 mt-4">
            <div className="card-body row">
                {(picture && picture.media_type === 'image') && (
                    <div className="col-sm-6 col-xs-12 py-4">
                        <a target="_blank" rel="noreferrer" href={picture.url}>
                            <img
                                style={{minHeight: "120px", width: "100%", height: "400px"}}
                                src={picture.url}
                                alt="Nasa"
                            /> 
                        </a>
                    </div>
                )}
                
                {(picture && picture.media_type === 'video') && (
                    <div className="col-sm-6 col-xs-12 py-4">
                        <iframe title={picture.title} className="ifrVideo" src={picture.url} frameborder="0"></iframe> 
                    </div>
                )}

                <div className="col-sm-6 col-xs-12" style={{minHeight: "400px"}}>
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="text-center">
                                <b>{picture.title}</b>
                            </h4>
                        </div>
                        <div className="col-sm-12">
                            <p className="text-justify">{picture.explanation}</p>
                        </div>

                        {(picture && picture.media_type === 'image') && (
                            <div className="col-sm-6 col-xs-12">
                                <div className="form-group mb-3">
                                    <a
                                        target="_blank"
                                        href={picture.hdurl}
                                        className="btn btn-info form-control"
                                        rel="noreferrer"
                                    >
                                        View in HD <i className="fa-solid fa-link"></i>
                                    </a>
                                </div>
                            </div>
                        )}

                        {(picture && picture.media_type === 'image') && (
                            <div className="col-sm-6 col-xs-12">
                                <div className="form-group mb-3">
                                    <a
                                        target="_blank"
                                        href={linkDownloadPicture(picture)}
                                        className="btn btn-success form-control"
                                        rel="noreferrer"
                                    >
                                        Download <i className="fa fa-download"></i>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-sm-12 mb-2 mt-1">
                        <a href="#" style={{
                            color: picture.favorite_by_me === true ? 'red' : 'blue'
                        }} onClick={(e) => {
                            e.preventDefault();
                            makeFavorite();
                        }}>
                            <i className="fa fa-heart"></i>
                        </a> {formatLikesText()}
                    </div>
                </div>
            </div>

            {(picture) && (
                <>
                    <div className="row">
                        <CommentsList picture={picture} />
                    </div>

                    
                </>
            )}

            {(fechaAnterior && fechaSiguiente) && (

                <div className="row">
                    <div className="col-sm-12">
                        <ul className="pagination justify-content-center">
                            <li className="page-item">
                                <a
                                href="#"
                                disabled="loading"
                                className="page-link"
                                onClick={(event) => {
                                    event.preventDefault();
                                    onNextOrPrevious(fechaAnterior)
                                }}
                                >
                                    <i className="fa fa-arrow-left"></i> Previous
                                </a>
                            </li>
                            {validDate(fechaSiguiente) && (
                                <li className="page-item" v-if="validDate()">
                                    <a
                                    href="#"
                                    disabled="loading"
                                    className="page-link"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onNextOrPrevious(fechaSiguiente)
                                    }}
                                    >
                                        Next <i className="fa fa-arrow-right"></i>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>

            )}
            
        </div>
    </>
)
}

export default Picture;