import React from 'react'
import * as moment from "moment";

export default function CommentItem({comment}) {
  return (
    <>
        <div className='commentItem'>
            <div>
                <div className='d-flex justify-content-between'>
                    <p>
                        {comment?.user?.name}
                    </p>

                    <p>
                        {moment(comment.createdAt).format("LLL")}
                    </p>
                </div>

                <p>{comment.content}</p>
            </div>
        </div>
    </>
  )
}
